import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Collapse from 'react-bootstrap/Collapse'
import { useStaticRestData } from "../hooks/data"
import Form from "./form";

const Footer = () => {
  const [openDisclamer, setOpenDisclamer] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      mapImg: file(relativePath: { eq: "map.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lrDC: file(relativePath: { eq: "lrdc.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testDriveImg: file(relativePath: { eq: "test-drive.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { allFormCallback, allFormTestDrive, allStaticBlock } = useStaticRestData()
  const disclaimerItem = allStaticBlock.nodes.filter(item => item.alternative_id === 5079)[0]
  return (
    <>
      <div
        className="test-drive-block"
        // data-sal="fade"
        // data-sal-delay="100"
        // data-sal-easing="ease"
      >
        
        <Form
          formName={allFormTestDrive.nodes[0].name}
          formItems={allFormTestDrive.nodes[0].items}
          template={allFormTestDrive.nodes[0].template}
          formSubmitUrl="https://api.osome.dev/v1/form?id=88"
          formShowLabel={false}
          hideTitle={true}
          submitText="Узнайте подробности"
        />
        <small>Сообщая данные сведения, я даю согласие на получение от Jaguar новостей, а также информации по специальным предложениям и рекламным акциям.
Пожалуйста, заполните форму. Поля, отмеченные *, обязательны для заполнения.</small>
      </div>
      <div
        id="test-drive"
        className="map test-drive-block"
        // data-sal="fade"
        // data-sal-delay="100"
        // data-sal-easing="ease"
      >
        <Img fluid={data.mapImg.childImageSharp.fluid} />

        <div className="map-block__text-block">
          <div className="map-list__item">
            <div className="row">
              <div className="col-12">
              <p className="map-list__caption">автобиография Центр</p>
                <Img fluid={data.lrDC.childImageSharp.fluid} />
              </div>
              <div className="col-12">
                <p className="map-block__phone"><i className="phone-white-icon"></i><b><a href="tel:+78122401000">+7 (812) 240 1000</a></b></p>
                <p className="map-list__addr"><i className="location-white-icon"></i><a href="https://yandex.ru/maps/-/CCQlVNFrPC" target="_blank">наб. обводного канала, 72</a></p>      
                <Form
                  formName={allFormCallback.nodes[0].name}
                  formItems={allFormCallback.nodes[0].items}
                  template={allFormCallback.nodes[0].template}
                  formSubmitUrl="https://api.osome.dev/v1/form?id=93"
                  formShowLabel={false}
                  modal={true}
                  modalButtonText="Заказать звонок"
                  hideTitle={true}
                  submitText="Заказать звонок"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="arrow-block">
        <ul className="social">
          <li></li>
          <li>
            <a href="https://vk.com/jaguar_landrover_spb" rel="noopener noreferrer" target="_blank"><i className="icon-vk"></i></a>
          </li>
        </ul>
        <span
          className="btn-top"
          onClick={() => setOpenDisclamer(!openDisclamer)}
          aria-controls="agreement"
          aria-expanded={openDisclamer}
        >
          подробнее
                </span>
        <Link className="btn-top" to="#main-header"><span>наверх</span></Link>
      </div>
      <div className="footer">
        <div id="disclaimer">
          <Collapse in={openDisclamer}>
            <div dangerouslySetInnerHTML={{ __html: disclaimerItem.detailText }} />
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default Footer