import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import Form from "./form";
import Slider from "./slider"
import { useStaticRestData } from "../hooks/data"

const Header = () => {
  const data = useStaticQuery(graphql`
  query {
    logoImg: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  } 
`);
  const { allSlider, allFormCallback, allFormCarPrice, allFormCarCredit } = useStaticRestData();
  return (
    <>
    <div className="header" id="main-header">
      <div className="logo hidden-xs">
        <a href="/"><Img fluid={data.logoImg.childImageSharp.fluid} /></a>
      </div>
      <div className="header_info">
        <div className="header_info__wrap">
          <div className="logo visible-xs">
            <a href="/"><Img fluid={data.logoImg.childImageSharp.fluid} /></a>
          </div>
          <div className="header_info__box">
            <h1 className="header_title hidden-xs">АВТОБИОГРАФИЯ ЦЕНТР ОФИЦИАЛЬНЫЙ ДИЛЕР JAGUAR</h1>
            <div className="header_title visible-xs">
            АВТОБИОГРАФИЯ ЦЕНТР<br/>
            <a href="tel:+78122401000">+7 (812) 240 1000</a>
            </div>
            <div className="phone">
              <Form 
                formName={allFormCallback.nodes[0].name}
                formItems={allFormCallback.nodes[0].items}
                template={allFormCallback.nodes[0].template}
                formSubmitUrl="https://api.osome.dev/api/v1/form?id=93"
                formShowLabel={false}
                modal={true}
                modalButtonText="Заказать звонок"
                hideTitle={true}
                submitText="Заказать звонок"
              />
            </div>
          </div>
          <div className="header_add">
            <ul>
              <li><i className="icon-location2"></i> <a href="tel:+78122401000">г. санкт-петербург, наб. Обводного канала, 72, +7 (812) 240 1000</a></li>
            </ul>
          </div>
        </div>
        <div className="header_menu">
          <ul>
            <li>
              <a href="https://eurosib-ab.ru/buy-car/new/jaguar?reversed=0&filterOrder=brands" target="_blank" rel="noopener noreferrer">купить он-лайн</a>
            </li>
            <li id="car-price">
              <Form 
                formName={allFormCarPrice.nodes[0].name}
                formItems={allFormCarPrice.nodes[0].items}
                template={allFormCarPrice.nodes[0].template}
                formSubmitUrl="https://api.osome.dev/api/v1/form?id=89"
                formShowLabel={false}
                modal={true}
                modalButtonText="Оценить автомобиль"
                hideTitle={true}
                submitText="Оценить автомобиль"
              />          
            </li>
            <li>
              <Form 
                formName={allFormCarCredit.nodes[0].name}
                formItems={allFormCarCredit.nodes[0].items}
                template={allFormCarCredit.nodes[0].template}
                formSubmitUrl="https://api.osome.dev/api/v1/form?id=90"
                formShowLabel={false}
                modal={true}
                modalButtonText="Заявка на кредит"
                hideTitle={true}
                submitText="Заявка на кредит"
              /> 
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Slider items={allSlider.nodes} formData={allFormCallback.nodes[0]}/>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header