import React from "react"
import PropTypes from "prop-types"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "./layout.css"

const Layout = ({ children }) => {
  //if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    //require("smooth-scroll")('a[href*="#"]')
  //}
  return (
    <div className="container">
      <Header/>
      <main>{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout