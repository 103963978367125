import { useStaticQuery, graphql } from "gatsby"
export const useStaticRestData = () => {
  const data = useStaticQuery(
    graphql`
    query {
        allSlider(filter: {props: {horizontal: {id: {}}}, id: {ne: "dummy"}}) {
          nodes {
            id
            alternative_id
            name
            detailText
            props {
              horizontal {
                alternative_id
                name
                value
              }
              txtLine {
                alternative_id
                name
                value
              }
              txtSubline {
                alternative_id
                name
                value
              }
            }
            localImageDesktop {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1680) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        allStaticBlock(filter: {name: {ne: null}}) {
          nodes {
            id
            alternative_id
            name
            detailText
          }
        }
        allFormCallback {
          nodes {
            name
            template
            items {
              alternative_id
              isEmail
              isPhone
              isRequired
              code
              title
              type
              userValue
              value {
                alternative_id
                value
                xmlId
              }
            }
          }
        }
        allFormCarPrice {
          nodes {
            name
            template
            items {
              alternative_id
              isEmail
              isPhone
              isRequired
              code
              title
              type
              userValue
              value {
                alternative_id
                value
                xmlId
              }
            }
          }
        }
        allFormCarCredit {
          nodes {
            name
            template
            items {
              alternative_id
              isEmail
              isPhone
              isRequired
              code
              title
              type
              userValue
              value {
                alternative_id
                value
                xmlId
              }
            }
          }
        }
        allFormTestDrive {
          nodes {
            name
            template
            items {
              alternative_id
              isEmail
              isPhone
              isRequired
              code
              title
              type
              userValue
              value {
                alternative_id
                value
                xmlId
              }
            }
          }
        }
        allFormOrderCar {
          nodes {
            name
            template
            items {
              alternative_id
              isEmail
              isPhone
              isRequired
              code
              title
              type
              userValue
              value {
                alternative_id
                value
                xmlId
              }
            }
          }
        }
        allCar(filter: {id: {ne: "dummy"}}) {
          nodes {
            alternative_id
            detailText
            id
            name
            previewText
            localDetailPicture {
              childImageSharp {
                fluid(quality: 80, maxWidth: 720) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            localPreviewPicture {
              childImageSharp {
                fluid(quality: 80, maxWidth: 190) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            props {
              price {
                alternative_id
                name
                value
              }
              profit {
                alternative_id
                name
                value
              }
            }
          }
        }
      }
    `
  )
  return data
}

// #price {
//   #alternative_id
//   #name
//   #value
// #}