const { headers } = require('./config')
/**
 * Check json body
 * @param response
 * @returns {*}
 */
async function checkBody(response) {
    const { error } = response;
    //Catch error
    if (error) {
        const objError = new Error(error);
        objError.response = response;
        throw objError;
    }

    return response;
}

/**
 * Requests a URL, returning a promise.
 * @param  {string} url        - The URL we want to request
 * @param  {object} options    - The options we want to pass to "fetch"
 */
export default async function request(url, options) {
    const newOptions = {...{}, ...options};
    newOptions.headers = {
        ...headers,
        ...options.headers,
    };
    if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
        newOptions.headers = {
            Accept: 'application/json',
            ...newOptions.headers,
        };
    }
    return fetch(url, newOptions)
        .then(response => response.json())
        .then(checkBody)
        .catch((error) => {
                return {
                    'success': false,
                    'error': error.message
                };
            }
        );
}